//! moment.js locale configuration
//! locale : ukrainian (uk)
//! author : zemlanin : https://github.com/zemlanin
//! Author : Menelion Elensúle : https://github.com/Oire

import moment from 'moment';

import type { TrelloWindow } from '@trello/window-types';

declare const window: TrelloWindow;

import { makeYearAwareCalendar } from './makeYearAwareCalendar';

function plural(forms: any, num: any) {
  return num % 10 === 1 && num % 100 !== 11
    ? forms[0]
    : num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)
    ? forms[1]
    : forms[2];
}
function relativeTimeWithPlural(num: any, withoutSuffix: any, key: any) {
  const format = {
    mm: ['хвилина', 'хвилини', 'хвилин'],
    hh: ['година', 'години', 'годин'],
    dd: ['день', 'дні', 'днів'],
    MM: ['місяць', 'місяці', 'місяців'],
    yy: ['рік', 'роки', 'років'],
  };
  if (key === 'm') {
    return withoutSuffix ? 'хвилина' : 'хвилину';
  } else if (key === 'h') {
    return withoutSuffix ? 'година' : 'годину';
  } else {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return `${num} ${plural(format[key], +num)}`;
  }
}
function monthsCaseReplace(m: any, format: any) {
  const months = {
    nominative: [
      'січень',
      'лютий',
      'березень',
      'квітень',
      'травень',
      'червень',
      'липень',
      'серпень',
      'вересень',
      'жовтень',
      'листопад',
      'грудень',
    ],
    accusative: [
      'січня',
      'лютого',
      'березня',
      'квітня',
      'травня',
      'червня',
      'липня',
      'серпня',
      'вересня',
      'жовтня',
      'листопада',
      'грудня',
    ],
  };
  const nounCase = /D[oD]? *MMMM?/.test(format) ? 'accusative' : 'nominative';

  return months[nounCase][m.month()];
}
function weekdaysCaseReplace(m: any, format: any) {
  const weekdays = {
    nominative: [
      'неділя',
      'понеділок',
      'вівторок',
      'середа',
      'четвер',
      'п’ятниця',
      'субота',
    ],
    accusative: [
      'неділю',
      'понеділок',
      'вівторок',
      'середу',
      'четвер',
      'п’ятницю',
      'суботу',
    ],
    genitive: [
      'неділі',
      'понеділка',
      'вівторка',
      'середи',
      'четверга',
      'п’ятниці',
      'суботи',
    ],
  };
  const nounCase = /(\[[ВвУу]\]) ?dddd/.test(format)
    ? 'accusative'
    : /\[?(?:минулої|наступної)? ?\] ?dddd/.test(format)
    ? 'genitive'
    : 'nominative';

  return weekdays[nounCase][m.day()];
}
function processHoursFunction(str: any) {
  return function (this: any) {
    return `${str}о${this.hours() === 11 ? 'б' : ''}] LT`;
  };
}

moment.locale(window.locale, {
  months: monthsCaseReplace,
  monthsShort: [
    'січ',
    'лют',
    'бер',
    'квіт',
    'трав',
    'черв',
    'лип',
    'серп',
    'вер',
    'жовт',
    'лист',
    'груд',
  ],
  weekdays: weekdaysCaseReplace,
  weekdaysShort: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  weekdaysMin: ['нд', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'],
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D MMMM YYYY р.',
    LLL: 'D MMMM YYYY р., HH:mm',
    LLLL: 'D MMMM',
  },
  // see `app/src/components/DateRangePicker/getLocalizedPlaceholder.ts`
  placeholder: {
    l: 'Д.М.РРРР',
    LT: 'Г:хв',
  },
  calendar: makeYearAwareCalendar({
    sameDay: processHoursFunction('[Сьогодні '),
    nextDay: processHoursFunction('[Завтра '),
    lastDay: processHoursFunction('[Вчора '),
    nextWeek: processHoursFunction('dddd ['),
    lastWeek() {
      switch (this.day()) {
        case 0:
        case 3:
        case 5:
        case 6:
          return processHoursFunction('[Минулої] dddd [').call(this);
        case 1:
        case 2:
        case 4:
          return processHoursFunction('[Минулого] dddd [').call(this);
        default:
          return;
      }
    },
    sameYear: processHoursFunction('llll ['),
    sameElse: processHoursFunction('ll ['),
  }),
  relativeTime: {
    future: 'за %s',
    past: '%s тому',
    s: 'декілька секунд',
    m: relativeTimeWithPlural,
    mm: relativeTimeWithPlural,
    h: 'годину',
    hh: relativeTimeWithPlural,
    d: 'день',
    dd: relativeTimeWithPlural,
    M: 'місяць',
    MM: relativeTimeWithPlural,
    y: 'рік',
    yy: relativeTimeWithPlural,
  },
  // M. E.: those two are virtually unused but a user might want to implement them for his/her website for some reason
  meridiemParse: /ночі|ранку|дня|вечора/,
  isPM(input) {
    return /^(дня|вечора)$/.test(input);
  },
  meridiem(hour, minute, isLower) {
    if (hour < 4) {
      return 'ночі';
    } else if (hour < 12) {
      return 'ранку';
    } else if (hour < 17) {
      return 'дня';
    } else {
      return 'вечора';
    }
  },
  ordinalParse: /\d{1,2}-(й|го)/,
  // @ts-expect-error TS(2322): Type '(num: any, period: any) => any' is not assig... Remove this comment to see the full error message
  ordinal(num: any, period: any) {
    switch (period) {
      case 'M':
      case 'd':
      case 'DDD':
      case 'w':
      case 'W':
        return `${num}-й`;
      case 'D':
        return `${num}-го`;
      default:
        return num;
    }
  },
  week: {
    dow: 1, // Monday is the first day of the week.
    doy: 7, // The week that contains Jan 1st is the first week of the year.
  },
});
